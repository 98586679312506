import React, { Component } from "react"
import styled from "styled-components"

export default class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      naam: "",
      email: "",
      telefoon: "",
      onderwerp: "",
      bericht: "",
      sent: false,
      error: false,
    }
  }

  handleSubmit = event => {
    event.preventDefault();

    const myForm = event.target;
    const formData = new FormData(myForm);

    fetch("/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() =>
        this.setState({
          naam: "",
          email: "",
          telefoon: "",
          onderwerp: "",
          bericht: "",
          sent: true,
        })
      )
      .catch(error =>
        this.setState({
          naam: "",
          email: "",
          telefoon: "",
          onderwerp: "",
          bericht: "",
          error: true,
        })
      )
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  resetEmailStatus = () => {
    this.setState({ status: "" })
  }
  render() {
    const {
      naam,
      email,
      telefoon,
      onderwerp,
      bericht,
      sent,
      error,
    } = this.state
    return (
      <div>
        <form
          onSubmit={this.handleSubmit}
          data-netlify="true"
          name="contact-hilde-van-heuven"
        >
          <input
            type="hidden"
            name="form-name"
            value="contact-hilde-van-heuven"
          />
          <StyledLabel htmlFor="name">Naam:</StyledLabel>
          <StyledInput
            type="text"
            id="naam"
            name="naam"
            value={naam}
            onChange={this.handleChange}
            minlength="3"
            maxlength="128"
          />
          <StyledLabel htmlFor="email">E-mail:</StyledLabel>
          <StyledInput
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={this.handleChange}
            minlength="3"
            maxlength="128"
          />
          <StyledLabel htmlFor="telefoon">Telefoon:</StyledLabel>
          <StyledInput
            type="text"
            id="telefoon"
            name="telefoon"
            value={telefoon}
            onChange={this.handleChange}
            minlength="3"
            maxlength="128"
          />
          <StyledLabel htmlFor="subject">Onderwerp:</StyledLabel>
          <StyledInput
            type="text"
            id="onderwerp"
            name="onderwerp"
            value={onderwerp}
            onChange={this.handleChange}
            minlength="3"
            maxlength="128"
          />
          <StyledLabel htmlFor="message">Bericht:</StyledLabel>
          <StyledTextArea
            id="bericht"
            name="bericht"
            rows="10"
            value={bericht}
            onChange={this.handleChange}
            minlength="3"
            maxlength="255"
          />
          <GdprCheck htmlFor="gdpr-checkbox">
            <input id="gdpr-checkbox" required type="checkbox" />
            Ik ga ermee akkoord dat mijn gegevens worden opgeslagen voor
            communicatiedoeleinden
          </GdprCheck>

          <ButtonHelper>
            <SubmitBtn type="submit" disabled={sent} className="cta">
              Verzenden
            </SubmitBtn>
          </ButtonHelper>
          {sent && <StateMessage>Verzonden!</StateMessage>}
          {error && (
            <StateMessage>Verzenden mislukt, probeer opnieuw</StateMessage>
          )}
        </form>
      </div>
    )
  }
}

const SubmitBtn = styled.button`
  background-color: #00628f;
  border-radius: 5px;
  border: none;
  font-family: "Patrick Hand";
  color: white;
  cursor: pointer;
  width: 100%;
  font-size: 1em;
  margin: 10px 0;
  min-width: initial;
  padding: 16px 25px;
  width: 100%;
  width: initial;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 0 20px 0 #00628f;, 0 1px 6px 1px rgba(0, 0, 0, 0.2);
    transform: translateY(-1px);
    transition: all 0.2s ease-in-out;
  }
  &:active {
    transform: translateY(0px);
  }
  &:disabled{
    background-color: grey;
    &:hover {
      box-shadow: none;
      transform: none;
      transition: none;
    }
  }
`

const ButtonHelper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StateMessage = styled.p`
  display: flex;
  justify-content: flex-end;
`

const StyledInput = styled.input`
  background: #effaff;
  border-radius: 6px;
  border: none;
  display: block;
  font-size: 1em;
  margin: 4px 0 20px;
  padding: 12px 5px;
  width: 100%;
  &:hover {
    box-shadow: inset 2px 2px 6px 0 rgba(0, 0, 0, 0.07);
  }
  & :focus {
    box-shadow: inset 1px 1px 6px 0 rgba(0, 0, 0, 0.07);
  }
`

const StyledTextArea = styled.textarea`
  background: #effaff;
  border-radius: 6px;
  border: none;
  font-size: 1em;
  padding: 12px 5px;
  resize: vertical;
  width: 100%;
  &:hover {
    box-shadow: inset 2px 2px 6px 0 rgba(0, 0, 0, 0.07);
  }
  & :focus {
    box-shadow: inset 1px 1px 6px 0 rgba(0, 0, 0, 0.07);
  }
`

const StyledLabel = styled.p`
  color: #333333;
`

const GdprCheck = styled.label`
  color: #333333;
  display: inline-block;
  cursor: pointer;
`
