import React from "react"
import { Flex, Box } from "reflexbox"
import styled from "../../config"
import ContactForm from "../../components/ContactForm"
import TextBlock from "../../components/TextBlock"
import Blob from "../../components/Blob"

import Praktijk1 from "../../images/foto_praktijk_1_comp.jpeg"
import Praktijk2 from "../../images/foto_praktijk_2_comp.jpeg"

const AfspraakMaken = () => (
  <AfspraakWrapper>
    <Blob cheight="250px" />
    <Flex justifyContent="center" py={5}>
      <h1>Afspraak maken</h1>
    </Flex>

    <Flex flexWrap="wrap">
      <Box p={3} width={[1, 1, 1, 1 / 2]}>
        <h2>Contactgegevens</h2>
        <TextBlock>Een afspraak maken kan:</TextBlock>
        <ul>
          <li>
            <b>Telefonisch</b> elke werkdag op het nummer{" "}
            <Styleda href="tel:0472 10 89 06">0472 10 89 06</Styleda>
          </li>
          <li>
            Via <b>mail</b>:{" "}
            <Styleda href="mailto:hilde.vanheuven@yahoo.com">
              hilde.vanheuven@yahoo.com
            </Styleda>
          </li>
          <li>
            Via het <b>contactformulier</b> op deze pagina
          </li>
        </ul>

        <TextBlock>
          Gesprekken kunnen 'face-to-face' doorgaan in de thuispraktijk in{" "}
          Brasschaat{" "}
          <Styleda
            href="https://goo.gl/maps/QnKiYiBk1oxs3WnU6?coh=178571&entry=tt"
            target="_blank"
            rel="noreferrer noopener"
          >
            (route)
          </Styleda>
        </TextBlock>
        <TextBlock>
          Zie{" "}
          <StyledLink href="#thuispraktijk">
            <b>foto's</b>
          </StyledLink>{" "}
          hieronder
        </TextBlock>
      </Box>
      <Box p={3} width={[1, 1, 1, 1 / 2]}>
        <h2>Contactformulier:</h2>
        <ContactForm />
      </Box>
    </Flex>
    <Flex flexWrap="wrap" id="thuispraktijk">
      <Card>
        <h2>Thuispraktijk Brasschaat</h2>
        <Styleda
          href="https://goo.gl/maps/QnKiYiBk1oxs3WnU6?coh=178571&entry=tt"
          target="_blank"
          rel="noreferrer noopener"
          className="vspace"
        >
          Rochuslei 41, 1ste verd, 2930 Brasschaat
        </Styleda>
        <Flex flexWrap="wrap">
          <StyledImg src={Praktijk1} alt="Thuispraktijk Brasschaat foto 1" />
          <StyledImg src={Praktijk2} alt="Thuispraktijk Brasschaat foto 2" />
        </Flex>
        <TextBlock>Gemakkelijk bereikbaar met de bus.</TextBlock>
        <TextBlock>
          Er is steeds voldoende parkeerplaats in de directe omgeving als je met
          de auto komt.
        </TextBlock>
      </Card>
    </Flex>
  </AfspraakWrapper>
)

export default AfspraakMaken

const AfspraakWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
`

const Styleda = styled.a`
  text-decoration: underline;
  color: #00628f;
  &.vspace {
    display: block;
    margin-bottom: 45px;
  }
`

const StyledImg = styled.img`
  max-width: 400px;
  max-height: 400px;
  margin-bottom: 45px;
  margin-right: 40px;
  border-radius: 5px;
  box-shadow: 0 0 11px 5px #0000002b;
`

const Card = styled.div`
  box-shadow: 0 0 11px 5px #00000008;
  padding: 25px;
  border-radius: 15px;
`

const StyledLink = styled.a`
  text-decoration: underline;
  color: #00628f;
`
