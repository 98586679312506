import React from "react"

import GlobalCollection from "../config/GlobalCollection"
import AfspraakMaken from "../modules/afspraak-maken/AfspraakMaken"

const AfspraakMakenPage = () => (
  <GlobalCollection>
    <AfspraakMaken />
  </GlobalCollection>
)

export default AfspraakMakenPage
